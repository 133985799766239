.title {

    font-size: 26;
    font-weight: 900;
    font-family: "Greycliff CF "
        /* ,var(--mantine-font-family) " */


}


.controls {
    @media (min-width: em(var(--mantine-breakpoint-xs))) {
        background-color: var(--mantine-color-red-filled);
        flex-direction: 'column-reverse',

    }

}